import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../../components/Layout';

const Honne = ({ data }) => (
  <Layout>
    <section className='honne'>
      <h1>honne - tour poster concept</h1>
      <h2>
        <Link to='/work'>2018/design</Link>
      </h2>

      <Img
        fluid={data.image1.childImageSharp.fluid}
        alt='honne poster'
        className='topimage'
      />

      <p>
        The «Honne» concert poster was made as a consept for their, then
        current, world tour.
        <br />
        The poster is designed around the tour and album name, titled «Love
        Me/Love Me Not»
      </p>

      <div className='banner' />

      <Img
        fluid={data.image3.childImageSharp.fluid}
        alt='poster from above'
        className='bottomimage'
      />
    </section>
  </Layout>
);

export const query = graphql`
  query honneimages {
    image1: file(relativePath: { eq: "honne/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "honne/3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Honne;
